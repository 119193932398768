import React, { useState, useRef } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { changePassword } from '../../api/SuperAdminActions'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const AddAdminPage: React.FC<Props> = ({ rootStore }) => {
  const [apiErrorMessage, setApiErrorMessage] = useState('')
  const [isApiError, setIsApiError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, errors, watch } = useForm()
  const [value, setValue] = useState(false)
  const password = useRef({})
  password.current = watch('new_password', '')
  const history = useHistory()

  const handleClick = () => {
    history.push('/dashboard')
  }

  // eslint-disable-next-line
  const handleKeyDown = (e: any) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const handleChange = () => {
    setValue(true)
  }

  const onSubmit = (data: Dictionary<string>) => {
    setIsLoading(true)
    setIsApiError(false)
    setApiErrorMessage('')

    const currentAdmin = localStorage.getItem('currentAdmin')
    if (currentAdmin === null) {
      return
    }
    const requestData = {
      email: currentAdmin,
      old_password: data.old_password,
      new_password: data.new_password
    }

    changePassword(requestData).then((response) => {
      setIsLoading(false)
      if (response.data.success === 0) {
        setIsApiError(true)
        setApiErrorMessage(response.data.message)
      } else {
        history.push('/dashboard')
      }
    }).catch((error) => {
      setIsLoading(false)
      setIsApiError(true)
      setApiErrorMessage(error.message)
    })
  }
  
  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className='card'>
      <div className='card-header'>
        Change Password
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col col col-md-8'>
            {isApiError && <div className='alert alert-danger' >
              {apiErrorMessage}
            </div>}
            <form onSubmit={handleSubmit(onSubmit)} >
              <fieldset>
                <div className="form-group">
                  <label>Old Password</label>
                  <input
                    ref={register({
                      required: 'This field is required'
                    })}
                    name='old_password'
                    type='password'
                    onKeyDown={handleKeyDown}
                    className={classNames('form-control', { 'is-invalid': errors.old_password })}
                    placeholder='Enter Old Password'
                    onChange={handleChange}
                  />
                  {errors.old_password && (<div className='invalid-feedback'>{errors.old_password.message}</div>)}
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    ref={register({
                      required: 'This field is required',
                      pattern: {
                        value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,11}$/,
                        message: 'Password must be at least 8 and at most 11 character long and should have one uppercase alphabet, one lowercase alphabet , one number and one special character'
                      }
                    })}
                    name='new_password'
                    type='password'
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className={classNames('form-control', { 'is-invalid': errors.new_password })}
                    placeholder='Enter New Password'
                  />
                  {errors.new_password && (<div className='invalid-feedback'>{errors.new_password.message}</div>)}
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    ref={register({
                      required: 'This field is required',
                      validate: value =>
                        value === password.current || 'Confirm password does not match'
                    })}
                    name='confirm_password'
                    type='password'
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    className={classNames('form-control', { 'is-invalid': errors.confirm_password })}
                    placeholder='Enter Confirm Password'
                  />
                  {errors.confirm_password && (<div className='invalid-feedback'>{errors.confirm_password.message}</div>)}
                </div>
                <div className='form-group'>
                  <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                    <span className={classNames('spinner-border spinner-border-sm', { 'd-none': !isLoading })} role='status' aria-hidden='true'></span>&nbsp;
                    {isLoading ? 'Loading...' : 'Save'}
                  </button>&nbsp;&nbsp;
                  <button type='reset' className='btn btn-outline-success' disabled={isLoading || !value}>Reset</button>
                  &nbsp;&nbsp;
                  <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(AddAdminPage)
