import React, { useEffect } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}


interface IField {
  label: string,
  fieldName: string
  // eslint-disable-next-line
  register: any
  // eslint-disable-next-line
  errors: any
  type: string
  defaultValue: string | number
}

const UpdatePlanPage: React.FC<Props> = ({ rootStore }) => {
  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()
  let planFlag: string
  // const [value, setValue] = useState(false)
  const { planStore } = rootStore
  const { isApiError, isLoading, apiErrorMessage, currentPlan, setPlanDetails, addEditPlan } = planStore
  const currentPlanPath = history.location.pathname.split('/')
  const currentPlanId = currentPlanPath[3]
  useEffect(() => {
    if (history.location.pathname !== '/plans/add') {
      setPlanDetails(currentPlanId)
    }
  }, [history.location.pathname, currentPlanId, setPlanDetails ])

  if(history.location.pathname === '/plans/add'){
    planFlag = 'Add Plan'
  }else{
    planFlag = 'Update Plan'
  }
  const onSubmit = (data: Dictionary<string>) => {
    addEditPlan(data, planFlag)
    history.push('/plans/')
  }
  
  const handleClick = () => {
    history.push('/plans/')
  }

  // eslint-disable-next-line
  const handleKeyDown = (e: any) => {
    if (e.key === ' ' && e.target.value === '') {
      e.preventDefault()
    }
  }

  const Field: React.FC<IField> = ({ label, fieldName, defaultValue, register, errors, type }) => {
    return <div className='form-group'>
      <label>{label}</label>
      <input 
        name={fieldName}
        ref={register({
          required: 'This field is required'
        })}
        type= {type}
        min='0'
        onKeyDown={handleKeyDown}
        defaultValue={defaultValue}
        // onChange={handleChange}
        className={classNames('form-control', { 'is-invalid': errors} )}
        placeholder= {'Enter ' + label}
      />
      {errors && (<div className='invalid-feedback'>{errors.message}</div>)}
    </div>
  }

 

  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className='card'>
      <div className='card-header'>
        {planFlag}
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col col col-md-8'>
            {isApiError && <div className='alert alert-danger' >
              {apiErrorMessage}
            </div>}
            {history.location.pathname === '/plans/add' || currentPlanId === currentPlan._id ? <form onSubmit={handleSubmit(onSubmit)} >
              <fieldset>
                <Field label= 'Title' fieldName= 'title' register= {register} errors= {errors.title} type= {'text'} defaultValue= {currentPlan.title}/>
                <div className='form-group'>
                  <label>Description</label>
                  <textarea 
                    name='description'
                    ref={register({
                      required: 'This field is required'
                    })}
                    onKeyDown={handleKeyDown}
                    defaultValue={currentPlan.description}
                    className={classNames('form-control', { 'is-invalid': errors.description })}
                    // onChange={handleChange}
                    placeholder= 'Enter Description'
                  />
                  {errors.description && (<div className='invalid-feedback'>{errors.description.message}</div>)}
                </div>
                <Field 
                  label= 'Maximum Organizations Allowed' 
                  fieldName= 'organizations' 
                  register= {register} 
                  errors= {errors.organizations} 
                  type= {'number'} 
                  defaultValue= {currentPlan.organizations}/>
                <Field 
                  label= 'Maximum Employees Allowed' 
                  fieldName= 'employees' 
                  register= {register} 
                  errors= {errors.employees} 
                  type= {'number'} 
                  defaultValue= {currentPlan.employees}/>
                <Field 
                  label= 'Monthly purchase id for iOS' 
                  fieldName= 'monthly_plan_ios' 
                  register= {register} 
                  errors= {errors.monthly_plan_ios} 
                  type= {'text'} 
                  defaultValue= {currentPlan.monthly_plan_ios}/>
                <Field 
                  label= 'Yearly purchase id for iOS' 
                  fieldName= 'yearly_plan_ios' 
                  register= {register} 
                  errors= {errors.yearly_plan_ios} 
                  type= {'text'} 
                  defaultValue= {currentPlan.yearly_plan_ios}/>
                <Field 
                  label= 'Monthly purchase id for Android' 
                  fieldName= 'monthly_plan_android' 
                  register= {register} 
                  errors= {errors.monthly_plan_android} 
                  type= {'text'} 
                  defaultValue= {currentPlan.monthly_plan_android}/>
                <Field 
                  label= 'Yearly purchase id for Android' 
                  fieldName= 'yearly_plan_android' 
                  register= {register} 
                  errors= {errors.yearly_plan_android} 
                  type= {'text'} 
                  defaultValue= {currentPlan.yearly_plan_android}/>
                <div className='form-group'>
                  <label>Active Status</label>
                  <div className='radio' >
                    <label><input name='active' type='radio' defaultChecked={currentPlan.active === true} value='true' ref={register} /> Active </label>&nbsp;&nbsp;
                    <label><input name='active' type='radio' defaultChecked={currentPlan.active === false} value='false' ref={register} /> Inactive</label>
                  </div>
                  {errors.active && (<div className='invalid-feedback'>{errors.active.message}</div>)}
                </div>    
                <div className='form-group'>
                  <label>Free</label>
                  <div className='radio' >
                    <label><input name='free' type='radio' defaultChecked={currentPlan.free === true} value='true' ref={register} /> Yes</label>&nbsp;&nbsp;
                    <label><input name='free' type='radio' defaultChecked={currentPlan.free === false} value='false' ref={register} /> No</label>
                  </div>
                  {errors.free && (<div className='invalid-feedback'>{errors.free.message}</div>)}
                </div>
                <div>
                  <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                    <span className={classNames('spinner-border spinner-border-sm', { 'd-none': !isLoading })} role='status' aria-hidden='true'></span>&nbsp;
                    {isLoading ? 'Loading...' : 'Submit'}
                  </button>&nbsp;&nbsp;
                  {history.location.pathname !== '/plans/add' && (<><button type='reset' className='btn btn-outline-success' disabled={isLoading}>Reset</button></>)}
                  &nbsp;&nbsp;
                  <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                </div><br />
              </fieldset>
            </form> : <div className="spinner-border " role="status">
              <span className="sr-only">Loading...</span>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(UpdatePlanPage)
