import { observable } from 'mobx'
import RootStore from './Root'
import { getSuperAdmins, viewProfile } from '../api/SuperAdminActions'

interface SuperAdminInformation {
  id?: string,
  first_name: string,
  last_name: string,
  email: string
}

interface SuperAdminData {
  currentPage: number,
  totalPage: number,
  totalItem: number,
  itemPerPage: number,
  isFiltered: boolean,
  totalAllItems: number,
  page_items: Array<SuperAdminInformation>
}

class SuperAdminStore {
  public rootStore: RootStore
  @observable public apiErrorMessage: string
  @observable public isApiError: boolean
  @observable public isLoading: boolean
  @observable public currentAdminData: SuperAdminInformation
  @observable public superAdminData: SuperAdminData

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.apiErrorMessage = ''
    this.isApiError = false
    this.isLoading = false
    this.currentAdminData = {
      first_name: '',
      last_name: '',
      email: ''
    }
    this.superAdminData = {
      currentPage: 0,
      totalPage: 0,
      totalItem: 0,
      itemPerPage: 0,
      isFiltered: false,
      totalAllItems: 0,
      page_items: []
    }
  }

  fetchCurrentAdminData = (): void => {
    const currentAdmin = localStorage.getItem('currentAdmin')
    if (currentAdmin === null) {
      return
    }
    this.isLoading = true
    viewProfile(currentAdmin).then((response) => {
      this.isLoading = false
      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      }
      this.currentAdminData = response.data.data
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }
      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }

  fetchSuperAdmin = (page: number, searchItem: string): void => {
    this.isLoading = true
    const requsetData = { page: page, search_item: searchItem }
    getSuperAdmins(requsetData).then((response) => {
      this.isLoading = false
      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      }
      this.superAdminData = response.data.data
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }
      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }
}

export default SuperAdminStore 
