import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import RootStore from '../../store/Root'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog, faSignOutAlt, faKey, faUser } from '@fortawesome/free-solid-svg-icons'

interface Props {
  rootstore: RootStore
}

const NavBar: React.FC<Props> = ({ rootstore }) => {

  const doLogout = () => {
    rootstore.authStore.logout()
  }

  const toggle = () => {
    rootstore.navStore.collapse = !rootstore.navStore.collapse
  }

  const { isUserLoggedIn } = rootstore.authStore

  return <>
    <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <NavLink className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" to="/">Attendance App</NavLink>
      <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation" onClick={toggle}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <ul className="navbar-nav px-3 ">
        <li className={classNames('nav-item dropdown dropdown-togle', { 'd-none': !isUserLoggedIn })}>
          <Link className="nav-link dropdown-link dropdown-toggle" to="#" role="button" data-toggle="dropdown"><FontAwesomeIcon icon={faUserCog} />&nbsp;&nbsp;Settings</Link>
          <div className="dropdown-content dropdown-menu-right" >
            <NavLink className="dropdown-item" to='/settings/profile/view'><FontAwesomeIcon icon={faUser} />&nbsp;&nbsp;View Profile</NavLink>
            <NavLink className="dropdown-item" to='/settings/password/edit'><FontAwesomeIcon icon={faKey} />&nbsp;&nbsp;Change Password</NavLink>
            <Link className="dropdown-item" to="#" onClick={doLogout}><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Sign out</Link>
          </div>
        </li>
        <li className={classNames('nav-item', { 'd-none': isUserLoggedIn })}>
          <NavLink className="nav-link" to="login">Login</NavLink>
        </li>
      </ul>
    </nav>
  </>
}

export default NavBar
