import React, { useEffect } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import classNames from 'classnames'

interface Props {
  rootStore: RootStore
}

const ViewProfilePage: React.FC<Props> = ({ rootStore }) => {
  const { isApiError, isLoading, apiErrorMessage, currentAdminData, fetchCurrentAdminData } = rootStore.superAdminStore

  useEffect(() => {
    fetchCurrentAdminData()
  }, [fetchCurrentAdminData])

  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className="card">
      <div className="card-header">
        View Profile
      </div>
      <div className="card-body">
        <div className='row'>
          <div className='col col-md-8'>
            <div className='row'>
              <div className='col d-flex align-items-center '>
                {isLoading && 
                <div className="spinner-border " role="status">
                  <span className="sr-only">Loading...</span>
                </div>}
              </div>
            </div>
            {isApiError && <div className='alert alert-danger' > {apiErrorMessage} </div>}
            <form >
              <fieldset>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    name='first_name'
                    type='text'
                    readOnly={true}
                    value={currentAdminData.first_name}
                    className={classNames('form-control')}
                    placeholder='First Name'
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    name='last_name'
                    type='text'
                    readOnly={true}
                    value={currentAdminData.last_name}
                    className={classNames('form-control')}
                    placeholder='Last Name'
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    name='email'
                    type='text'
                    readOnly={true}
                    value={currentAdminData.email}
                    className={classNames('form-control')}
                    placeholder='Email'
                  />
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(ViewProfilePage)
