/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import RootStore from '../store/Root'
import Container from './layout/Container'
import { observer } from 'mobx-react'
import Table from './table/Table'
import Pagination from './table/Pagination'
import { useForm } from 'react-hook-form'
import { Cell } from './table/Definations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Modal from './commonComponent/modal'
import _ from 'lodash'
import { UpworkJobLoader } from './commonComponent/DotLoader'
import { makeUserProOrRemove } from '../api/UserAction'
interface Props {
  rootStore: RootStore,
}

const UsersPage: React.FC<Props> = ({ rootStore }) => {
  const [searchItem, setSearchItem] = useState('')
  const [page, setPage] = useState(1)
  const [showModal, setShow] = useState(false)
  const { register, handleSubmit } = useForm()
  const { getPlansTitleId, planTitleId } = rootStore.planStore
  const [selectedRow, setSelectedRow] = useState({ _id: '' })
  const [planId, setPlanId] = useState('')
  const [isLoadingUserPro, setUserProIsLoading] = useState(false)
  const [isApiErrorInPro, setIsApiErrorPro] = useState(false)
  const [proApiErrorMsg, setProApiErrorMsg] = useState('')
  const { isApiError, isLoading, apiErrorMessage, userData, fetchUsers } = rootStore.userStore

  useEffect(() => {
    fetchUsers(page, searchItem)
  }, [fetchUsers, page, searchItem])

  const loadPage = (page: number) => {
    setPage(page)
    fetchUsers(page, searchItem)
  }

  const reload = () => window.location.reload()

  const openCloseModal = () => {
    setShow(!showModal)
    setPlanId('')
    setProApiErrorMsg('')
    setIsApiErrorPro(false)
  }

  const onSubmit = (searchItem: any) => {
    setPage(1)
    setSearchItem(searchItem.search)
    fetchUsers(1, searchItem.search)
  }

  const onTableAction = (action: string, value: string, data: any) => {
    setSelectedRow({ _id: value })
    if(!data.is_pro){
      getPlansTitleId()
    }
    openCloseModal()
  }
  const onSaveModal = () => {
    const requestData = {
      user_id: selectedRow._id,
      pro_plan: planId
    }
    setUserProIsLoading(true)
    makeUserProOrRemove(requestData).then((response) => {
      setUserProIsLoading(false)
      if (response.data.success === 0) {
        setIsApiErrorPro(true)
        setProApiErrorMsg(response.data.message)
        return
      }else {
        openCloseModal()
        reload()
      }
    }).catch((e: any) => {
      setUserProIsLoading(false)
      setIsApiErrorPro(true)
      setProApiErrorMsg(e.message)
    })
  }

  const handleOnChangePlan = (e: any) => {
    setPlanId(e.currentTarget.value)
  }
  const isValueEquivalent = (a: any, b: any) => {
    if (!_.isEqual(a, b)) {
      return false
    }
    return true
  }

  const TableCellProUser: React.FC<Cell> = (props) => {
    const value = props.value || ''
    const data = props.data || {}

    return (
      <>
        <input type='checkbox' className='checkbox-lg' name='check_pro_user' defaultChecked={data.is_pro} onChange={() => {
          if (onTableAction != null) {
            onTableAction('', value, data)
          }
        }} />
        {showModal && !data.is_pro && isValueEquivalent({ _id: value }, selectedRow) ? <Modal
          modalTitle='Choose Plan'
          show={showModal}
          openCloseModal={openCloseModal}
          onSaveModal={onSaveModal}
          isModalError={isApiErrorInPro}
          modalErrorMessage={proApiErrorMsg}
          saveButtonName='OK'>
          <>
            {isLoadingUserPro ?<UpworkJobLoader />:''}
            <div className='m-2 p-4'>
              Active Plans:
              {isLoading ? <UpworkJobLoader /> :
                <>
                  <div className='m-2'>
                    {planTitleId.map((titleId) => (
                      <>
                        <div>
                          <label><input type='radio' value={titleId._id} name='pro_user' className='mr-2' checked={titleId._id === planId} onChange={handleOnChangePlan} />{titleId.title}</label>
                        </div>
                      </>
                    ))}
                  </div>
                </>}
            </div>
            <div className='text-wrap'>
              Please choose any one plan which you want to assign to {data.first_name + ' ' + data.last_name} user as Pro plan
            </div>
          </>
        </Modal> : null}
        {showModal && data.is_pro && isValueEquivalent({ _id: value }, selectedRow) ? <Modal
          modalTitle='Cancel Pro user facility'
          show={showModal}
          openCloseModal={openCloseModal}
          onSaveModal={onSaveModal}
          isModalError={isApiErrorInPro}
          modalErrorMessage={proApiErrorMsg}
          saveButtonName='Yes'>
          <>
            <div className='m-2 p-4 text-wrap'>
              Are you sure you want to remove the user <b> {data.first_name + ' ' + data.last_name}</b> from <b>Pro users</b> ?
            </div>
          </>
        </Modal> : null}
      </>
    )
  }

  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className='card mb-5'>
      <div className='card-header'>
        Users
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <div className='col d-flex align-items-center '>
                {isLoading &&
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>}
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex ml-auto '>
                <form className="form-inline mb-4 ml-auto" onSubmit={handleSubmit(onSubmit)}>
                  <input className="form-control mr-sm-2" name='search' ref={register} type="text" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-primary" type="submit" ><FontAwesomeIcon icon={faSearch} /></button>
                </form>
              </div>
            </div>
            {isApiError && <div className='alert alert-danger' > {apiErrorMessage} </div>}
            <div className='table-responsive'>
              <Table
                unique_key='_id'
                columns={[
                  { name: 'first_name', title: 'First Name' },
                  { name: 'last_name', title: 'Last Name' },
                  { name: 'email', title: 'Email' },
                  { name: '_id', title: 'Pro User', component: TableCellProUser },
                  { name: 'createdAt', title: 'Created Date' }
                ]}
                data={userData.page_items}
                onTableAction={onTableAction}
              ></Table>
            </div>
            {userData.isFiltered === true && userData.page_items.length === 0 && isLoading === false ? <div className='col d-flex justify-content-center'><p>No matching records found</p></div> : <></>}
            <Pagination
              currentPage={userData.currentPage}
              totalPages={userData.totalPage}
              totalItems={userData.totalItem}
              itemsPerPage={userData.itemPerPage}
              isFiltered={userData.isFiltered}
              totalAllItems={userData.totalAllItems}
              onItemClick={loadPage}
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
  </Container >
}

export default observer(UsersPage)
