import { observable } from 'mobx'
import RootStore from './Root'
import { getPlans, getPlanDetails, editPlan, addPlan, getPlansTitleId } from '../api/PlanActions'

interface PlanInformation {
  _id?: string,
  title: string,
  description: string,
  organizations: number,
  monthly_plan_ios: string,
  yearly_plan_ios: string,
  monthly_plan_android: string,
  yearly_plan_android: string,
  employees: number,
  active: boolean,
  free: boolean
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}
interface PlanData {
  currentPage: number,
  totalPage: number,
  totalItem: number,
  itemPerPage: number,
  isFiltered: boolean,
  totalAllItems: number,
  page_items: Array<PlanInformation>
}

interface TitleId {
  _id: string,
  title: string
}

class PlanStore {
  public rootStore: RootStore
  @observable public apiErrorMessage: string
  @observable public isApiError: boolean
  @observable public isLoading: boolean
  @observable public planData: PlanData
  @observable public currentPlan: PlanInformation
  @observable public planTitleId: Array<TitleId>

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.apiErrorMessage = ''
    this.isApiError = false
    this.isLoading = false
    this.planData = {
      currentPage: 0,
      totalPage: 0,
      totalItem: 0,
      itemPerPage: 0,
      isFiltered: false,
      totalAllItems: 0,
      page_items: []
    }
    this.currentPlan = {
      _id: '',
      title: '',
      description: '',
      organizations: 0,
      monthly_plan_ios: '',
      yearly_plan_ios: '',
      monthly_plan_android: '',
      yearly_plan_android: '',
      employees: 0,
      active: true,
      free: true
    }
    this.planTitleId = []
  }
  removePlanDetails(): void {
    this.currentPlan = {
      _id: '',
      title: '',
      description: '',
      organizations: 0,
      monthly_plan_ios: '',
      yearly_plan_ios: '',
      monthly_plan_android: '',
      yearly_plan_android: '',
      employees: 0,
      active: true,
      free: true
    }
  }

  getPlansTitleId = async (): Promise<void> => {
    this.isLoading = true
    getPlansTitleId().then((response) => {
      this.isLoading = false
      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      } else {
        this.planTitleId = response.data.data
        this.isApiError = false
        this.apiErrorMessage = ''
      }
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }
      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }

  setPlanDetails = (value: string): void => {
    this.isLoading = true
    getPlanDetails(value).then((response) => {
      this.isLoading = false
      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      }
      this.currentPlan = response.data.data
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }
      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }

  addEditPlan = (data: Dictionary<string>, planFlag: string): void => {
    if (data.active === 'true') {
      data.active = true
    } else {
      data.active = false
    }
    if (data.free === 'true') {
      data.free = true
    } else {
      data.free = false
    }
    this.isLoading = true
    this.isApiError = false
    this.apiErrorMessage = ''
    if (planFlag === 'Update Plan') {
      const requestData = {
        plan_id: this.currentPlan._id,
        title: data.title,
        description: data.description,
        organizations: data.organizations,
        monthly_plan_ios: data.monthly_plan_ios,
        yearly_plan_ios: data.yearly_plan_ios,
        monthly_plan_android: data.monthly_plan_android,
        yearly_plan_android: data.yearly_plan_android,
        employees: data.employees,
        active: data.active,
        free: data.free
      }
      editPlan(requestData).then((response) => {
        this.isLoading = false
        if (response.data.success === 0) {
          this.isApiError = true
          this.apiErrorMessage = response.data.message
          return
        }
        this.currentPlan.active = requestData.active
        this.currentPlan.free = requestData.free
      }).catch((error) => {
        if (error.request.status === 401) {
          this.rootStore.authStore.setAuthToken('')
        }
        this.isLoading = false
        this.isApiError = true
        this.apiErrorMessage = error.message
      })
    } else if (planFlag === 'Add Plan') {
      const requestData = {
        title: data.title,
        description: data.description,
        organizations: data.organizations,
        monthly_plan_ios: data.monthly_plan_ios,
        yearly_plan_ios: data.yearly_plan_ios,
        monthly_plan_android: data.monthly_plan_android,
        yearly_plan_android: data.yearly_plan_android,
        employees: data.employees,
        active: data.active,
        free: data.free
      }
      addPlan(requestData).then((response) => {
        this.isLoading = false
        if (response.data.success === 0) {
          this.isApiError = true
          this.apiErrorMessage = response.data.message
          return
        }
      }).catch((error) => {
        if (error.request.status === 401) {
          this.rootStore.authStore.setAuthToken('')
        }
        this.isLoading = false
        this.isApiError = true
        this.apiErrorMessage = error.message
      })
    }
  }

  fetchPlans = (page: number, searchItem: string): void => {
    this.isLoading = true
    const requsetData = { page: page, search_item: searchItem }
    getPlans(requsetData).then((response) => {
      this.isLoading = false
      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      }
      this.planData = response.data.data
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }
      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }
}

export default PlanStore
