import React, { useEffect, useState } from 'react'
import RootStore from '../store/Root'
import Container from './layout/Container'
import { observer } from 'mobx-react'
import Table from './table/Table'
import Pagination from './table/Pagination'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface Props {
  rootStore: RootStore,
}

const TimezonesPage: React.FC<Props> = ({ rootStore }) => {
  const [searchItem, setSearchItem] = useState('')
  const [page, setPage] = useState(1)
  const { register, handleSubmit } = useForm()

  const { isApiError, isLoading, apiErrorMessage, timezoneData, fetchTimezones } = rootStore.timezoneStore

  useEffect(() => {
    fetchTimezones(page, searchItem)
  }, [fetchTimezones, page, searchItem])

  const loadPage = (page: number) => {
    setPage(page)
    fetchTimezones(page, searchItem)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (searchItem: any) => {
    setPage(1)
    setSearchItem(searchItem.search)
    fetchTimezones(1, searchItem.search)
  }
  
  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className="card mb-5">
      <div className="card-header">
        Timezones
      </div>
      <div className="card-body">
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <div className='col d-flex align-items-center '>
                {isLoading &&
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>}
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex ml-auto '>
                <form className="form-inline mb-4 ml-auto" onSubmit={handleSubmit(onSubmit)}>
                  <input className="form-control mr-sm-2" name='search' ref={register} type="text" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-primary" type="submit" ><FontAwesomeIcon icon={faSearch} /></button>
                </form>
              </div>
            </div>
            {isApiError && <div className='alert alert-danger' > {apiErrorMessage} </div>}
            <div className='table-responsive'>
              <Table
                unique_key='_id'
                columns={[
                  { name: 'timezone_name', title: 'Timezone Name' },
                  { name: 'offset', title: 'Offset' }
                ]}
                data={timezoneData.page_items}
              ></Table>
            </div>
            {timezoneData.isFiltered === true && timezoneData.page_items.length === 0 && isLoading === false ? <div className='col d-flex justify-content-center'><p>No matching records found</p></div> : <></>}
            <Pagination
              currentPage={timezoneData.currentPage}
              totalPages={timezoneData.totalPage}
              totalItems={timezoneData.totalItem}
              itemsPerPage={timezoneData.itemPerPage}
              isFiltered={timezoneData.isFiltered}
              totalAllItems={timezoneData.totalAllItems}
              onItemClick={loadPage}
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(TimezonesPage)
