import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import LoginPage from './components/LoginPage'
import RootStore from './store/Root'
import DashboardPage from './components/DashboardPage'
import PageNotFound from './components/PageNotFound'
import UsersPage from './components/UsersPage'
import OrganizationsPage from './components/OrganizationsPage'
import TimezonesPage from './components/TimezonesPage'
import EmployeesPage from './components/EmployeesPage'
import PlansPage from './components/Plan/PlansPage'
import AddUpdatePlanPage from './components/Plan/AddUpdatePlanPage'
import AddAdminPage from './components/SuperAdmin/AddAdminPage'
import ViewProfilePage from './components/SuperAdmin/ViewProfilePage'
import ChangepaswordPage from './components/SuperAdmin/ChangePasswordPage'
import SuperAdminsPage from './components/SuperAdmin/SuperAdminsPage'
import AppVersions from './components/AppVersionsPage'

const rootStore = new RootStore()

const App: React.FC = () => {
  return <Router>
    <Switch>
      <Route exact path='/' render={(...props) => <DashboardPage {...props} rootStore={rootStore} />} />
      <Route exact path='/login' render={(...props) => <LoginPage {...props} rootStore={rootStore} />} />
      <Route exact path='/dashboard' render={(...props) => <DashboardPage {...props} rootStore={rootStore} />} />
      <Route exact path='/users/' render={(...props) => <UsersPage {...props} rootStore={rootStore} />} />
      <Route exact path='/organizations/' render={(...props) => <OrganizationsPage {...props} rootStore={rootStore} />} />
      <Route exact path='/timezones/' render={(...props) => <TimezonesPage {...props} rootStore={rootStore} />} />
      <Route exact path='/employees/:organization_id' render={(...props) => <EmployeesPage {...props} rootStore={rootStore} />} />
      <Route exact path='/plans/' render={(...props) => <PlansPage {...props} rootStore={rootStore} />} />
      <Route exact path='/plans/add' render={(...props) => <AddUpdatePlanPage {...props} rootStore={rootStore} />} />
      <Route exact path='/plans/edit/:id' render={(...props) => <AddUpdatePlanPage {...props} rootStore={rootStore} />} />
      <Route exact path='/super-admins/add' render={(...props) => <AddAdminPage {...props} rootStore={rootStore} />} />
      <Route exact path='/super-admins/' render={(...props) => <SuperAdminsPage {...props} rootStore={rootStore} />} />
      <Route exact path='/settings/profile/view' render={(...props) => <ViewProfilePage {...props} rootStore={rootStore} />} />
      <Route exact path='/settings/password/edit' render={(...props) => <ChangepaswordPage {...props} rootStore={rootStore} />} />
      <Route exact path='/app-versions' render={(...props) => <AppVersions {...props} rootStore={rootStore} />} />
      <Route render={(...props) => <PageNotFound {...props} rootStore={rootStore} />} />
    </Switch>
  </Router>
}

export default App
