import React, { useEffect, useState } from 'react'
import RootStore from '../store/Root'
import Container from './layout/Container'
import { observer } from 'mobx-react'
import Chart from 'react-google-charts'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useForm } from 'react-hook-form'

interface Props {
  rootStore: RootStore
}

interface ICard {
  title: string,
  count: number
}

const options = {
  hAxis: {
    title: 'Days',
    gridlines: { count: 13 }
  },
  vAxis: {
    title: 'New Users',
    format: '0'
  }
}

const Card: React.FC<ICard> = ({ title, count }) => {
  return <div className='card'>
    <div className='card-body text-center'>
      <h5 className='card-title'>{title}</h5>
      <p className='card-text'>{count}</p>
    </div>
  </div>
}

const DashboardPage: React.FC<Props> = ({ rootStore }) => {
  const { dashboardStore, chartDetails } = rootStore
  const { isApiError, isLoading, apiErrorMessage, entityCounts, fetchCounts } = dashboardStore
  const { chartDataCount, chartData } = chartDetails
  const [startDate, setStartDate] = useState(new Date(Date.now() - 13 * 24 * 60 * 60 * 1000))
  const [endDate, setEndDate] = useState(new Date())
  const [isDateRangeError, setIsDateRangeError] = useState(false)
  const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState('')
  const { errors } = useForm()
  const [showDatePicker, setShowDatePicker] = useState(false)
  useEffect(() => {
    fetchCounts()
    chartDataCount(startDate, endDate)
  }, [fetchCounts, chartDataCount, startDate, endDate])
  const [range, setRange] = useState({
    startDate: new Date(Date.now() - 13 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: 'selection'
  })
  const openCloseDatePicker = () => {
    setIsDateRangeError(false)
    setDateRangeErrorMessage('')
    setShowDatePicker(!showDatePicker)
  }
  const handleOnClick = () => {
    setStartDate(range.startDate)
    setEndDate(range.endDate)
    openCloseDatePicker()
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateRangeChange = (ranges: any) => {
    const daysCount = (ranges.selection.endDate.getTime() - ranges.selection.startDate.getTime()) / (24 * 60 * 60 * 1000)
    setIsDateRangeError(false)
    setDateRangeErrorMessage('')
    if (daysCount > 13.99999999999) {
      setIsDateRangeError(true)
      setDateRangeErrorMessage('You cannot select more than 14 days')
    } else {
      setRange(ranges.selection)
    }
  }
  const data = chartData
  const formatedStartDate = startDate.toDateString().split(' ')
  const formatedEndDate = endDate.toDateString().split(' ')
  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className='card main-card'>
      <div className='card-header'>
        {isLoading ? 'Loading...' : 'Dashboard'}
      </div>
      <div className='card-body'>
        {isApiError && <div className='alert alert-danger' > {apiErrorMessage}
        </div>}
        <div className='card'>
          <div className='card-header'>
            Entity Count
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col col-md-3'>
                <Card title='Organizations' count={entityCounts.organizations}></Card>
              </div>
              <div className='col col-md-3'>
                <Card title='Empoyees' count={entityCounts.employees}></Card>
              </div>
              <div className='col col-md-3'>
                <Card title='Users' count={entityCounts.users}></Card>
              </div>
              <div className='col col-md-3'>
                <Card title='TimeZones' count={entityCounts.timezones}></Card>
              </div>
            </div>
          </div>
        </div>
        <div className='card mt-4'>
          <div className='card-header'>
            Chart
          </div>
          <div className='card-body'>
            <label className='font-size'>Note: You can select maximum 14 days of range</label>
            <div className='row mb-4'>
              <div className='col-sm-auto pr-0'>
                <label className='mr-0 mt-2'>Date Range</label>
              </div>
              <div className='col-sm-auto inline-flex'>
                <input
                  name='date_range'
                  className='form-control'
                  data-target='#datePicker'
                  value={formatedStartDate[1] + ' ' + formatedStartDate[2] + ' ' + formatedStartDate[3] + ' - ' + formatedEndDate[1] + ' ' + formatedEndDate[2] + ' ' + formatedEndDate[3]}
                  onClick={openCloseDatePicker}
                />
                {showDatePicker ? (
                  <div className='card date collapsed mb-5' id='datePicker'>
                    <div className='card-body'>
                      {isDateRangeError && <div className='responsive alert-danger rounded p-2 mb-2' > {dateRangeErrorMessage} </div>}
                      <DateRangePicker
                        className='border'
                        data-toggle='collapse'
                        direction='horizontal'
                        ranges={[range]}
                        maxDate={new Date()}
                        onChange={handleDateRangeChange}>
                      </DateRangePicker>
                    </div>
                    <div className='card-footer text-right'>
                      <button className='btn btn-outline-secondary mr-2' onClick={openCloseDatePicker}>Close</button>
                      <button className='btn btn-outline-primary' onClick={handleOnClick} >Apply</button>
                    </div>
                  </div>
                ) : null}
                {errors.date_range && (<div className='invalid-feedback'>{errors.date_range.message}</div>)}
              </div>
            </div>
            {data.length !== 0 ? <Chart
              width={'100%'}
              height={'400px'}
              chartType="LineChart"
              loader={<div>Loading....</div>}
              options={options}
              data={data}
            /> : <div>Loading....</div>}
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(DashboardPage)