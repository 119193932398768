import { observable } from 'mobx'
import RootStore from './Root'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

interface JwtDecoded {
  exp: number
}

class AuthStore {

  public rootStore: RootStore
  @observable public authToken: string
  @observable public currentAdmin: string
  @observable public isUserLoggedIn: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.authToken = ''
    this.currentAdmin = ''
    this.isUserLoggedIn = false
    this.restoreToken()
  }

  logout(): void {
    this.setAuthToken('')
  }

  setAuthToken = (token: string): void => {
    if (token) {
      this.authToken = token
      axios.defaults.headers.common['token'] = 'Bearer ' + token
      localStorage.setItem('token', token)
      // Apply token token to every request if logged in
      this.isUserLoggedIn = true
    } else {
      // Delete auth header
      delete axios.defaults.headers.common['token']
      localStorage.removeItem('token')
      this.isUserLoggedIn = false
    }
  }

  setCurrentAdmin = (email: string): void => {
    this.currentAdmin = email
    localStorage.setItem('currentAdmin', email)
  }

  restoreToken = (): void => {
    const token = localStorage.getItem('token')
    if (token != null) {
      this.authToken = token
      axios.defaults.headers.common['token'] = 'Bearer ' + token
      const decoded: JwtDecoded = jwt_decode(token)
      // Check for expired token
      const currentTime = Date.now() / 1000
      if (decoded.exp < currentTime) {
        this.setAuthToken('')
      } else {
        this.setAuthToken(token)
      }
    }
  }

}

export default AuthStore 
