import ContentLoader from 'react-content-loader'
import React from 'react'

export const UpworkJobLoader = (props: any) => {
  return (
    <div className='modal-loader'> <ContentLoader viewBox="0 0 778 116" width={778} height={116} {...props}>
      <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
      <rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
      <rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
      <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
      <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
    </ContentLoader></div>
  )
}

export const FormLoader = (props: any) => {
  return (
    <div className='modal-loader'> <ContentLoader viewBox="0 0 881 443"  width={881}
      height={443} {...props}>
      <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="15" rx="0" ry="0" width="258" height="25" />
      <rect x="434" y="71" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="50" rx="0" ry="0" width="749" height="25" />
   
      <rect x="37" y="110" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="110" rx="0" ry="0" width="258" height="25" />
      <rect x="434" y="173" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="147" rx="0" ry="0" width="749" height="25" />
  
      <rect x="37" y="250" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="212 " rx="0" ry="0" width="258" height="25" />
      <rect x="434" y="250" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="249" rx="0" ry="0" width="749" height="25" />
      
      <rect x="37" y="300" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="313" rx="0" ry="0" width="258" height="25" />
      <rect x="434" y="250" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="350" rx="0" ry="0" width="749" height="25" />
  
      <rect x="10" y="410" rx="0" ry="0" width="0" height="0" />
      <rect x="10" y="410" rx="0" ry="0" width="80" height="55" />
      <rect x="110" y="410" rx="0" ry="0" width="0" height="0" />
      <rect x="110" y="410" rx="0" ry="0" width="80" height="55" />
    
    </ContentLoader></div>
  )
}