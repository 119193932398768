import React, { useState, useRef } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { addNewAdmin } from '../../api/SuperAdminActions'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const AddAdminPage: React.FC<Props> = ({ rootStore }) => {
  const [apiErrorMessage, setApiErrorMessage] = useState('')
  const [isApiError, setIsApiError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, errors, watch } = useForm()
  const history = useHistory()
  const password = useRef({})
  password.current = watch('password', '')
  const handleClick = () => {
    history.push('/super-admins/')
  }

  // eslint-disable-next-line
  const handleKeyDown = (e: any) => {
    if (e.key === ' ' && e.target.value === '') {
      e.preventDefault()
    }
  }

  // eslint-disable-next-line
  const handleKeyDownWithOutSpace = (e: any) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  const onSubmit = (data: Dictionary<string>) => {
    setIsLoading(true)
    setIsApiError(false)
    setApiErrorMessage('')

    const requesrData = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      password: data.password
    }
    addNewAdmin(requesrData).then((response) => {
      setIsLoading(false)
      if (response.data.success === 0) {
        setIsApiError(true)
        setApiErrorMessage(response.data.message)
      } else {
        history.push('/super-admins/')
      }
    }).catch((error) => {
      setIsLoading(false)
      setIsApiError(true)
      setApiErrorMessage(error.message)
    })
  }
  
  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className='card'>
      <div className='card-header'>
        Add new Admin
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col col col-md-8'>
            {isApiError && <div className='alert alert-danger' >
              {apiErrorMessage}
            </div>}
            <form onSubmit={handleSubmit(onSubmit)} >
              <fieldset>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    ref={register({
                      required: 'This field is required'
                    })}
                    name='first_name'
                    type='text'
                    onKeyDown={handleKeyDown}
                    className={classNames('form-control', { 'is-invalid': errors.first_name })}
                    placeholder='Enter First Name'
                  />
                  {errors.first_name && (<div className='invalid-feedback'>{errors.first_name.message}</div>)}
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    ref={register({
                      required: 'This field is required'
                    })}
                    name='last_name'
                    type='text'
                    onKeyDown={handleKeyDown}
                    className={classNames('form-control', { 'is-invalid': errors.last_name })}
                    placeholder='Enter Last Name'
                  />
                  {errors.last_name && (<div className='invalid-feedback'>{errors.last_name.message}</div>)}
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    ref={register({
                      required: 'This field is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    name='email'
                    type='text'
                    onKeyDown={handleKeyDownWithOutSpace}
                    className={classNames('form-control', { 'is-invalid': errors.email })}
                    placeholder='Enter email'
                  />
                  {errors.email && (<div className='invalid-feedback'>{errors.email.message}</div>)}
                </div>
                <div className='form-group'>
                  <label>Password</label>
                  <input
                    name='password'
                    ref={register({
                      required: 'This field is required',
                      pattern: {
                        value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,11}$/,
                        message: 'Password must be at least 8 and at most 11 character long and should have one uppercase alphabet, one lowercase alphabet , one number and one special character'
                      }
                    })}
                    type='password'
                    onKeyDown={handleKeyDownWithOutSpace}
                    className={classNames('form-control', { 'is-invalid': errors.password })}
                    placeholder='Enter password'
                  />
                  {errors.password && (<div className='invalid-feedback'>{errors.password.message}</div>)}
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    ref={register({
                      required: 'This field is required',
                      validate: value =>
                        value === password.current || 'Confirm password does not match'
                    })}
                    name='confirm_password'
                    type='password'
                    onKeyDown={handleKeyDownWithOutSpace}
                    className={classNames('form-control', { 'is-invalid': errors.confirm_password })}
                    placeholder='Enter Confirm Password'
                  />
                  {errors.confirm_password && (<div className='invalid-feedback'>{errors.confirm_password.message}</div>)}
                </div>
                <div className='form-group'>
                  <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                    <span className={classNames('spinner-border spinner-border-sm', { 'd-none': !isLoading })} role='status' aria-hidden='true'></span>&nbsp;
                    {isLoading ? 'Loading...' : 'Submit'}
                  </button>&nbsp;&nbsp;
                  <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(AddAdminPage)
