import { observable } from 'mobx'
import RootStore from './Root'
import { chartData } from '../api/DashboardActions'


class ChartDataStore {

  public rootStore: RootStore
  @observable public apiErrorMessage: string
  @observable public isApiError: boolean
  @observable public isLoading: boolean
  @observable public chartData: Array<[]>

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.apiErrorMessage = ''
    this.isApiError = false
    this.isLoading = false
    this.chartData = []
  }

  chartDataCount = (startDate: Date, endDate: Date): void => {
    this.isLoading = true
    this.isApiError = false
    this.apiErrorMessage = ''
    const requestData = {
      startDate: new Date(startDate.setHours(0)),
      endDate: new Date(endDate.setHours(0))
    }
    chartData(requestData).then((response) => {
      this.isLoading = false
      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      }
      this.chartData = response.data.data
      return
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }
      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }
}
export default ChartDataStore 