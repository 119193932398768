import { observable } from 'mobx'
import RootStore from './Root'
import { getAppVersion, saveAppVersion } from '../api/AppVersionAction'

interface Dictionary<T> {
  [Key: string]: T;
}

interface AppVersionInfo {
  _id: string,
  android_version_code: string,
  ios_version_code: string,
  title: string,
  message: string,
  is_force_update: boolean
}

class AppVersionStore {

  public rootStore: RootStore
  @observable public apiErrorMessage: string
  @observable public isApiError: boolean
  @observable public isLoading: boolean
  @observable public appVersionData: AppVersionInfo

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.isLoading = true
    this.isApiError = false
    this.apiErrorMessage = ''
    this.appVersionData = {
      _id: '',
      android_version_code: '',
      ios_version_code: '',
      title: '',
      message: '',
      is_force_update: false
    }
    this.set()
  }

  set = (): void => {
    this.isLoading = true
    this.isApiError = false
    this.apiErrorMessage = ''

    getAppVersion().then((response) => {
      this.isLoading = false
      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      }
      this.appVersionData = response.data.data
      return
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }
      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }

  update = (requestData: Dictionary<string>): void => {
    this.isLoading = true
    this.isApiError = false
    this.apiErrorMessage = ''
   
    saveAppVersion(requestData).then((response) => {
      this.isLoading = false

      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      }
      this.set()
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }

      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }
}
export default AppVersionStore