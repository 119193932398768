import axios, { AxiosResponse } from 'axios'
const baseURL = process.env.REACT_APP_BASEURL

export const login = (email: string, password: string): Promise<AxiosResponse> => {
  const url = `${baseURL}/web/login`
  return axios.post(url, { email, password })
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const getSuperAdmins = (requestData: object): Promise<AxiosResponse> => {
  const url = `${baseURL}/superAdmins/`
  return axios.post(url, requestData)
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const addNewAdmin = (requestData: object): Promise<AxiosResponse> => {
  const url = `${baseURL}/superAdmins/add`
  return axios.post(url, requestData)
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const changePassword = (requestData: object): Promise<AxiosResponse> => {
  const url = `${baseURL}/password/edit`
  return axios.post(url, requestData)
}

export const viewProfile = (email: string): Promise<AxiosResponse> => {
  const url = `${baseURL}/profile/view`
  return axios.post(url, { email })
}

export default { getSuperAdmins, login, addNewAdmin, changePassword, viewProfile }