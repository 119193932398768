import React, { useEffect, useState } from 'react'
import RootStore from '../store/Root'
import Container from './layout/Container'
import { observer } from 'mobx-react'
import Table from './table/Table'
import { Link } from 'react-router-dom'
import Pagination from './table/Pagination'
import { Cell } from './table/Definations'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface Props {
  rootStore: RootStore,
}

const TableCellAction: React.FC<Cell> = (props) => {
  let value = props.value || ''
  const data = props.data || {}
  const onTableAction = props.onTableAction || null

  // Convert boolean values to string so that they are visible in table
  if (value === true || value === false) {
    value = value.toString()
  }

  return (
    <>
      <Link to={`/employees/${value}`} onClick={() => {
        if (onTableAction != null) {
          onTableAction('employees', value, data)
        }
      }}>View Employees</Link>
    </>
  )
}

const TableCellIsActiveIsGeofence: React.FC<Cell> = (props) => {
  const value = props.value
  const status = (value === 1) ? 'Yes' : 'No'

  return (
    <>
      {status}
    </>
  )
}

const OrganizationsPage: React.FC<Props> = ({ rootStore }) => {
  const [searchItem, setSearchItem] = useState('')
  const [page, setPage] = useState(1)
  const { register, handleSubmit } = useForm()

  const { isApiError, isLoading, apiErrorMessage, organizationData, fetchOrganizations } = rootStore.organizationStore
  
  useEffect(() => {
    fetchOrganizations(page, searchItem)
  }, [fetchOrganizations, page, searchItem])

  const loadPage = (page: number) => {
    setPage(page)
    fetchOrganizations(page, searchItem)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTableAction = (action: string, value: any, data: any) => {
    localStorage.setItem('organizationName', data.organization_name)
    rootStore.employeeStore.setOrganizationId(data._id)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (searchItem: any) => {
    setPage(1)
    setSearchItem(searchItem.search)
    fetchOrganizations(1, searchItem.search)
  }

  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className='card mb-5'>
      <div className='card-header'>
        Organizations
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <div className='col d-flex align-items-center '>
                {isLoading &&
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>}
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex ml-auto '>
                <form className="form-inline mb-4 ml-auto" onSubmit={handleSubmit(onSubmit)}>
                  <input className="form-control mr-sm-2" name='search' ref={register} type="text" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-primary" type="submit" ><FontAwesomeIcon icon={faSearch} /></button>
                </form>
              </div>
            </div>
            {isApiError && <div className='alert alert-danger' > {apiErrorMessage} </div>}
            <div className='table-responsive'>
              <Table
                unique_key='_id'
                columns={[
                  { name: 'organization_name', title: 'Name' },
                  { name: 'organization_timezone', title: 'Timezone' },
                  { name: 'is_geofence', title: 'Is Geofence', component: TableCellIsActiveIsGeofence },
                  { name: 'is_active', title: 'Is Active', component: TableCellIsActiveIsGeofence },
                  { name: 'createdAt', title: 'Created Date' },
                  { name: '_id', title: 'Employees', component: TableCellAction }
                ]}
                data={organizationData.page_items}
                onTableAction={onTableAction}
              ></Table>
            </div>
            {organizationData.isFiltered === true && organizationData.page_items.length === 0 && isLoading === false ? <div className='col d-flex justify-content-center'><p>No matching records found</p></div> : <></>}
            <Pagination
              currentPage={organizationData.currentPage}
              totalPages={organizationData.totalPage}
              totalItems={organizationData.totalItem}
              isFiltered={organizationData.isFiltered}
              totalAllItems={organizationData.totalAllItems}
              itemsPerPage={organizationData.itemPerPage}
              onItemClick={loadPage}
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(OrganizationsPage)
