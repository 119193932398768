import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { useHistory, Link } from 'react-router-dom'
import { Cell } from '../table/Definations'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const TableCellAction: React.FC<Cell> = (props) => {
  let value = props.value || ''

  // Convert boolean values to string so that they are visible in table
  if (value === true || value === false) {
    value = value.toString()
  }

  return (
    <>
      <Link to={`/plans/edit/${value}`}> Edit</Link>
    </>
  )
}

const TableCellActiveInactive: React.FC<Cell> = (props) => {
  const value = props.value
  const status = (value === true) ? 'Active' : 'Inactive'
  return (
    <>
      {status}
    </>
  )
}

const TableCellFree: React.FC<Cell> = (props) => {
  const value = props.value
  const status = (value === 'true') ? 'Yes' : 'No'
  return (
    <>
      {status}
    </>
  )
}

interface Props {
  rootStore: RootStore
}

const PlansPage: React.FC<Props> = ({ rootStore }) => {
  const [searchItem, setSearchItem] = useState('')
  const [page, setPage] = useState(1)
  const { register, handleSubmit } = useForm()

  const { isApiError, isLoading, apiErrorMessage, planData, fetchPlans } = rootStore.planStore

  useEffect(() => {
    fetchPlans(page, searchItem)
  }, [fetchPlans, page, searchItem])

  const loadPage = (page: number) => {
    setPage(page)
    fetchPlans(page, searchItem)
  }

 
  const history = useHistory()

  const handleClick = () => {
    rootStore.planStore.removePlanDetails()
    history.push('/plans/add')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (searchItem: any) => {
    setPage(1)
    setSearchItem(searchItem.search)
    fetchPlans(1, searchItem.search)
  }

  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className='card mb-5'>
      <div className='card-header'>
        <div className='row ml-auto'>
          Plans
          <Link to='/plans/add' className='ml-auto px-3' onClick={handleClick}>Add Plan</Link>
        </div>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <div className='col d-flex align-items-center '>
                {isLoading &&
                  <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>}
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex ml-auto '>
                <form className="form-inline mb-4 ml-auto" onSubmit={handleSubmit(onSubmit)}>
                  <input className="form-control mr-sm-2" name='search' ref={register} type="text" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-primary" type="submit" ><FontAwesomeIcon icon={faSearch} /></button>
                </form>
              </div>
            </div>
            {isApiError && <div className='alert alert-danger' > {apiErrorMessage} </div>}
            <div className='table-responsive'>
              <Table
                unique_key='_id'
                columns={[
                  { name: 'title', title: 'Title' },
                  { name: 'description', title: 'Description' },
                  { name: 'organizations', title: 'Organizations' },
                  { name: 'employees', title: 'Employees' },
                  { name: 'monthly_plan_ios', title: 'Monthly Plan for ios' },
                  { name: 'yearly_plan_ios', title: 'Yearly Plan for ios' },
                  { name: 'monthly_plan_android', title: 'Monthly Plan for Android' },
                  { name: 'yearly_plan_android', title: 'Yearly Plan for Android' },
                  { name: 'active', title: 'Active Status', component: TableCellActiveInactive },
                  { name: 'free', title: 'Free', component: TableCellFree },
                  { name: '_id', title: 'Action', component: TableCellAction }
                ]}
                data={planData.page_items}
              ></Table>
            </div>
            {planData.isFiltered === true && planData.page_items.length === 0 && isLoading === false ? <div className='col d-flex justify-content-center'><p>No matching records found</p></div> : <></>}
            <Pagination
              currentPage={planData.currentPage}
              totalPages={planData.totalPage}
              totalItems={planData.totalItem}
              itemsPerPage={planData.itemPerPage}
              isFiltered={planData.isFiltered}
              totalAllItems={planData.totalAllItems}
              onItemClick={loadPage}
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(PlansPage)
