import React from 'react'
import { NavLink } from 'react-router-dom'
import RootStore from '../../store/Root'
import classNames from 'classnames'
import './sidebar.css'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt, faUser, faFileAlt, faCalendarCheck, faBuilding, faUserFriends, faCodeBranch } from '@fortawesome/free-solid-svg-icons'

interface Props {
  rootstore: RootStore
}

const SideBar: React.FC<Props> = ({ rootstore }) => {

  return <>
    <nav className={classNames('col-md-3 col-lg-2 d-md-block bg-light sidebar collapse', { 'show': rootstore.navStore.collapse })}>
      <div className='sidebar-sticky pt-3'>
        <ul className='nav flex-column'>
          <li className='nav-item'>
            <NavLink className="nav-link" to="/dashboard" activeClassName='active' > <FontAwesomeIcon icon={faTachometerAlt} />&nbsp;&nbsp;Dashboard</NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className="nav-link" to='/users/' activeClassName='active' ><FontAwesomeIcon icon={faUserFriends} />&nbsp;&nbsp;User</NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className="nav-link" to='/organizations/' activeClassName='active' ><FontAwesomeIcon icon={faBuilding} />&nbsp;&nbsp;&nbsp;Organization</NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className="nav-link" to='/timezones/' activeClassName='active' ><FontAwesomeIcon icon={faCalendarCheck} />&nbsp;&nbsp;&nbsp;Timezone</NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className="nav-link" to='/plans' activeClassName='active' ><FontAwesomeIcon icon={faFileAlt} />&nbsp;&nbsp;&nbsp; Plan</NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className="nav-link" to='/super-admins/' activeClassName='active' ><FontAwesomeIcon icon={faUser} />&nbsp;&nbsp;&nbsp;Super Admin</NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className="nav-link" to='/app-versions/' activeClassName='active' ><FontAwesomeIcon icon={faCodeBranch} />&nbsp;&nbsp;&nbsp;App Versions</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  </>
}

export default observer(SideBar)
