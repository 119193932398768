import { observable } from 'mobx'
import RootStore from './Root'
import { getUsers } from '../api/UserAction'

interface UserInformation {
  _id: string,
  first_name: string,
  last_name: string,
  email: string,
  created_at: Date,
  is_pro:boolean
}

interface UserData {
  currentPage: number,
  totalPage: number,
  totalItem: number,
  itemPerPage: number,
  isFiltered: boolean,
  totalAllItems: number,
  page_items: Array<UserInformation>
}

class UserStore {
  public rootStore: RootStore
  @observable public apiErrorMessage: string
  @observable public isApiError: boolean
  @observable public isLoading: boolean
  @observable public userData: UserData

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.apiErrorMessage = ''
    this.isApiError = false
    this.isLoading = false
    this.userData = {
      currentPage: 0,
      totalPage: 0,
      totalItem: 0,
      itemPerPage: 0,
      isFiltered: false,
      totalAllItems: 0,
      page_items: []
    }
  }

  fetchUsers = (page: number, searchItem: string): void => {
    this.isLoading = true
    const requsetData = { page: page, search_item: searchItem }
    getUsers(requsetData).then((response) => {
      this.isLoading = false
      if (response.data.success === 0) {
        this.isApiError = true
        this.apiErrorMessage = response.data.message
        return
      }
      this.userData = response.data.data
    }).catch((error) => {
      if (error.request.status === 401) {
        this.rootStore.authStore.setAuthToken('')
      }
      this.isLoading = false
      this.isApiError = true
      this.apiErrorMessage = error.message
    })
  }
}

export default UserStore 
