import React from 'react'

interface Props {
  modalTitle: string
  show: boolean
  saveButtonName?: string
  isModalError?: boolean
  modalErrorMessage?: string
  modalDialogClass?: string
  openCloseModal?: () => void
  onSaveModal?: () => void
  isScrollable?: boolean,
  disabled? : boolean
}
const Modal: React.FC<Props> = (props) => {
  const defaultModalDialogClass = 'modal-dialog modal-dialog-scrollable '
  const showModal = props.show
  const modalTitle = props.modalTitle
  const saveButtonName = props.saveButtonName || 'Save changes'
  const openCloseModal = props.openCloseModal
  const onSaveModal = props.onSaveModal
  const isModalError = props.isModalError || false
  const modalErrorMessage = props.modalErrorMessage || ''
  const modalDialogClass = (props.modalDialogClass === undefined) ? defaultModalDialogClass : defaultModalDialogClass + props.modalDialogClass
  const isScrollable = props.isScrollable
  const disabled = props.disabled || false 

  const styles = showModal ? { display: 'block' } : { display: 'none' }
  const modalClass = showModal ? 'modal fade in show' : 'modal in fade'
  const backDropStyle = showModal ? { opacity: .5 } : { display: 'none' }
  const scrollableBodyStyle = isScrollable === false ? 'modal-body resource' : 'modal-body'

  return (
    <>
      {showModal && <div className='modal-backdrop' style={backDropStyle}></div>}
      <div id="myModal" className={modalClass} role="dialog" style={styles}>
        <div className={modalDialogClass}>
          <div className="modal-content">
            <div className="modal-header">
              <div className='modal-title'> {modalTitle} </div>
              <button type="button" className="close" onClick={openCloseModal} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className={scrollableBodyStyle} >
              {isModalError && <div className='responsive alert-danger p-3 mb-4 rounded' > {modalErrorMessage} </div>}
              {props.children}
            </div>
            <div className='modal-footer'>
              <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
              <button type="button" className="btn btn-outline-primary" onClick={onSaveModal} disabled={disabled}>{saveButtonName}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal