import React from 'react'
import _ from 'lodash'
import TableCellText from './TableCellText'

interface Cell {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableAction?: (action: string, value: any, data: any) => void
}

interface Column {
  name: string,
  title: string,
  class?: string,
  component?: React.FC<Cell>
}

interface SortConfig {
  key: string,
  direction: string
}

interface Props {
  unique_key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<any>
  columns: Array<Column>
  shouldSort?: (sortConfig: SortConfig) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableAction?: (action: string, value: any, data: any) => void
}

const Table: React.FC<Props> = (props) => {
  const shouldSort = props.shouldSort
  const onTableAction = props.onTableAction
  const [sortConfig, setSortConfig] = React.useState({ key: '_id', direction: 'asc' })

  const requestSort = (key: string) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const firstUpdate = React.useRef(true)
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if (shouldSort) {
      shouldSort(sortConfig)
    }
  }, [shouldSort, sortConfig])

  const columns = props.columns || []
  const data = props.data || []
  const unique_key = props.unique_key || '_id'

  return (
    <table className="table table-bordered table-hover">
      <thead>
        <tr>
          {columns.map((col) => {
            const columnName = col.name
            return <th key={columnName} onClick={() => {
              const isSortable = _.get(col, 'sort', false)
              if (isSortable === false) {
                return
              }
              requestSort(columnName)
            }}>{col.title}{sortConfig.key === columnName ? <>{sortConfig.direction === 'asc' ? <>↓</> : <>↑</>}</> : null}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => {
          return <tr key={row[unique_key]}>
            {columns.map((col) => {
              const className = col.class ? col.class : ''
              const columnName = col.name
              const component = col.component
              const value = _.get(row, col.name, '')

              // Convert boolean values to string so that they are visible in table
              // if (value === true || value === false || value === 0) {
              //   value = value.toString()
              // }

              const Component = component || TableCellText
              const RenderComponent = <Component value={value} data={row} onTableAction={onTableAction}></Component>

              return <td key={columnName} className={className} >
                {RenderComponent}
              </td>
            })}
          </tr>
        })}
      </tbody>
    </table>
  )
}

export default Table