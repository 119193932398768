import React from 'react'
import RootStore from '../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import Container from './layout/Container'
import { FormLoader } from './commonComponent/DotLoader'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  [Key: string]: T;
}

const AppVersionPage: React.FC<Props> = ({ rootStore }) => {
  const { register, handleSubmit, errors, setValue } = useForm()
  const { appVersionStore } = rootStore
  const { isApiError, isLoading, apiErrorMessage, update, appVersionData } = appVersionStore

  const onSubmit = async (data: Dictionary<string>) => {
    console.log(data, 'data')
    data._id = appVersionData._id
    appVersionData.android_version_code = data.android_version_code
    appVersionData.ios_version_code = data.ios_version_code
    appVersionData.message = data.message
    appVersionData.title = data.title
    await update(data)

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIsForceUpdate = (e: any) => {
    const value = e.target.checked
    appVersionData.is_force_update = value
    if (value) {
      setValue('is_force_update', value)
    }
  }

  return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
    <div className='card'>
      <div className='card-header'>
        App Version Details
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col col col-md-8'>
            {isApiError && <div className='alert alert-danger' >
              {apiErrorMessage}
            </div>}
            {(appVersionData !== undefined && !isLoading ) ?
              <form  onSubmit={handleSubmit(onSubmit)} >
                <fieldset>
                  <div className='form-group'>
                    <label>Android Version Code</label>
                    <input
                      name='android_version_code'
                      ref={register({
                        required: 'This field is required'
                      })}
                      type='text'
                      defaultValue={appVersionData.android_version_code}
                      className={classNames('form-control', { 'is-invalid': errors.android_version_code })}
                      placeholder='Enter Android Version Code'
                    />
                    {errors.android_version_code && (<div className='invalid-feedback'>{errors.android_version_code.message}</div>)}
                  </div>

                  <div className='form-group'>
                    <label>IOS Version Code</label>
                    <input
                      name='ios_version_code'
                      ref={register({
                        required: 'This field is required'
                      })}
                      type='text'
                      defaultValue={appVersionData.ios_version_code}
                      className={classNames('form-control', { 'is-invalid': errors.ios_version_code })}
                      placeholder='Enter IOS Version Code'
                    />
                    {errors.ios_version_code && (<div className='invalid-feedback'>{errors.ios_version_code.message}</div>)}
                  </div>

                  <div className='form-group'>
                    <label>Title</label>
                    <input
                      name='title'
                      ref={register({
                        required: 'This field is required'
                      })}
                      type='text'
                      defaultValue={appVersionData.title}
                      className={classNames('form-control', { 'is-invalid': errors.title })}
                      placeholder='Enter title'
                    />
                    {errors.title && (<div className='invalid-feedback'>{errors.title.message}</div>)}
                  </div>

                  <div className='form-group'>
                    <label>Message</label>
                    <textarea
                      name='message'
                      ref={register({
                        required: 'This field is required'
                      })}
                      defaultValue={appVersionData.message}
                      className={classNames('form-control', { 'is-invalid': errors.message })}
                      placeholder='Enter Message'
                    />
                    {errors.message && (<div className='invalid-feedback'>{errors.message.message}</div>)}
                  </div>

                  <div className="form-group mt-1 custom-switch">
                    <input type="checkbox" checked={appVersionData.is_force_update}
                      ref={register} onChange={handleIsForceUpdate}
                      className="custom-control-input" name='is_force_update' id="isForceUpdate" />
                    <label className="custom-control-label" htmlFor='isForceUpdate'>Is Force Update</label>
                    {errors.is_force_update && (<div className='invalid-feedback'>{errors.is_force_update.message}</div>)}
                  </div>

                  <div>
                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                      Submit
                    </button>&nbsp;&nbsp;
                    <button type='reset' className='btn btn-outline-success' disabled={isLoading}>Reset</button>
                  </div>
                </fieldset>
              </form>
              : <FormLoader />}
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default observer(AppVersionPage)
