import React from 'react'
import NavBar from './NavBar'
import FooterBar from './Footer'
import SideBar from './SideBar'
import RootStore from '../../store/Root'
import { Redirect } from 'react-router-dom'

interface Props {
  rootStore: RootStore,
  redirectIfNotLoggedIn?: boolean
}

const Container: React.FC<Props> = (props) => {
  const rootStore = props.rootStore
  if (props.redirectIfNotLoggedIn === true) {
    if (rootStore.authStore.isUserLoggedIn === false) {
      return <Redirect to="/login" />
    }
  }

  return <>
    <NavBar rootstore={props.rootStore} />
    <div className='container-fluid'>
      <div className='row'>
        <SideBar rootstore={props.rootStore}></SideBar>
        <main className='col-md-9 ml-sm-auto d-md-block col-lg-10 px-md-4 mt-3'>
          {props.children}
        </main>
      </div>
    </div>
    <FooterBar rootstore={props.rootStore} />
  </>
}

export default Container
