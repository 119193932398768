import axios, { AxiosResponse } from 'axios'
const baseURL = process.env.REACT_APP_BASEURL

export const getDashboardCount = (): Promise<AxiosResponse> => {
  const url = `${baseURL}/entity/counts/`
  return axios.get(url)
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const chartData = (requestData: Object): Promise<AxiosResponse> => {
  const url = `${baseURL}/chart/`
  return axios.post(url, requestData)
}


export default { getDashboardCount, chartData }
